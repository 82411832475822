// Do not modify, this file was generated
// to modify, reference the files in dibs-sassy/src/colors/*

export const colors = {
    sassyColorAlertPrimary: '#cc0000',
    sassyColorAlertSecondary: '#950808',
    sassyColorAlertTertiary: '#fedcde',
    sassyColorWarningPrimary: '#f07f04',
    sassyColorWarningSecondary: '#ffc100',
    sassyColorSuccessPrimary: '#66a559',
    sassyColorSuccessSecondary: '#2a7140',
    sassyColorSuccessTertiary: '#aedfa3',
    sassyColorBuyerPrimary: '#c2a661',
    sassyColorBuyerSecondary: '#a48e45',
    sassyColorBuyerTertiary: '#f6f3eb',
    sassyColorBuyerTertiaryAlt: '#e8dfcd',
    sassyColorBuyerPrimaryIphone: '#cca738',
    sassyColorDealerPrimary: '#436b93',
    sassyColorDealerSecondary: '#204664',
    sassyColorDealerTertiary: '#dee3eb',
    sassyColorDealerTertiaryAlt: '#d8ecf5',
    sassyColorBlue50: '#eef1f7',
    sassyColorBlue700: '#375d81',
    sassyColorBlue800: '#204664',
    sassyColorBlue950: '#193148',
    sassyColorGreen50: '#eff9ea',
    sassyColorGreen100: '#e5f4de',
    sassyColorGreen200: '#CEECC1',
    sassyColorGreen700: '#418f48',
    sassyColorGreen900: '#1B5633',
    sassyColorGreen950: '#0E3C1E',
    sassyColorRed50: '#fef2f2',
    sassyColorRed200: '#fecaca',
    sassyColorRed700: '#cc0000',
    sassyColorRed900: '#770303',
    sassyColorShagreen50: '#edf3f3',
    sassyColorShagreen300: '#bacecc',
    sassyColorShagreen400: '#aabeb9',
    sassyColorShagreen600: '#73919B',
    sassyColorShagreen700: '#517884',
    sassyColorShagreen800: '#2e5f6c',
    sassyColorShagreen950: '#223f4c',
    sassyColorTangerine500: '#f9902d',
    sassyColorYellow50: '#fff4e0',
    sassyColorYellow100: '#ffe9b6',
    sassyColorYellow200: '#ffdf8b',
    sassyColorYellow300: '#ffd660',
    sassyColorYellow700: '#be9116',
    sassyColorYellow950: '#5d4a20',
    sassyColorGray700: '#555555',
    sassyColorCyan50: '#e8f4f9',
    sassyColorCyan200: '#c6e6f1',
    sassyColorCyan600: '#2683a3',
    sassyColorWhite: '#fff',
    sassyColorAlabaster: '#fbfbfb',
    sassyColorMoonstone: '#f3f3f3',
    sassyColorDolphin: '#ddd',
    sassyColorStorm: '#bbb',
    sassyColorIron: '#888',
    sassyColorSatan: '#666',
    sassyColorCharcoal: '#444',
    sassyColorNoir: '#222',
    sassyColorPitchBlack: '#000',
    sassyColorOverlayBackground: 'rgba(0, 0, 0, 0.7)',
    sassyColorOverlayLight: 'rgba(255, 255, 255, 0.4)',
    sassyColorInternalPrimary: '#559b5e',
    sassyColorInternalSecondary: '#2a7140',
    sassyColorInternalTertiary: '#b8e7c8',
    sassyColorMarketingSale: '#950808',
    sassyColorNftWine: '#610034',
    sassyColorMedalBronze: '#a06931',
    sassyColorMedalSilver: '#637583',
    sassyColorMedalGold: '#8c7236',
    sassyColorMedalPlatinum: '#727383',
    sassyColorPinterestPrimary: '#e00002',
    sassyColorFacebookPrimary: '#3b5998',
    sassyColorInstagramPrimary: '#275a85',
    sassyColorLinkedinPrimary: '#007bb6',
    sassyColorTradePrimary: '#0f8d88',
    sassyColorTradeSecondary: '#0d7d77',
    sassyColorTradeTertiary: '#e7f4f3',
    sassyColorTradeMember: '#666',
} as const;
export const colorPrefix = 'sassyColor' as const;
export const colorVars = {
    sassyColorAlertPrimary: '$sassy-color-alert-primary',
    sassyColorAlertSecondary: '$sassy-color-alert-secondary',
    sassyColorAlertTertiary: '$sassy-color-alert-tertiary',
    sassyColorWarningPrimary: '$sassy-color-warning-primary',
    sassyColorWarningSecondary: '$sassy-color-warning-secondary',
    sassyColorSuccessPrimary: '$sassy-color-success-primary',
    sassyColorSuccessSecondary: '$sassy-color-success-secondary',
    sassyColorSuccessTertiary: '$sassy-color-success-tertiary',
    sassyColorBuyerPrimary: '$sassy-color-buyer-primary',
    sassyColorBuyerSecondary: '$sassy-color-buyer-secondary',
    sassyColorBuyerTertiary: '$sassy-color-buyer-tertiary',
    sassyColorBuyerTertiaryAlt: '$sassy-color-buyer-tertiary-alt',
    sassyColorBuyerPrimaryIphone: '$sassy-color-buyer-primary-iphone',
    sassyColorDealerPrimary: '$sassy-color-dealer-primary',
    sassyColorDealerSecondary: '$sassy-color-dealer-secondary',
    sassyColorDealerTertiary: '$sassy-color-dealer-tertiary',
    sassyColorDealerTertiaryAlt: '$sassy-color-dealer-tertiary-alt',
    sassyColorBlue50: '$sassy-color-blue-50',
    sassyColorBlue700: '$sassy-color-blue-700',
    sassyColorBlue800: '$sassy-color-blue-800',
    sassyColorBlue950: '$sassy-color-blue-950',
    sassyColorGreen50: '$sassy-color-green-50',
    sassyColorGreen100: '$sassy-color-green-100',
    sassyColorGreen200: '$sassy-color-green-200',
    sassyColorGreen700: '$sassy-color-green-700',
    sassyColorGreen900: '$sassy-color-green-900',
    sassyColorGreen950: '$sassy-color-green-950',
    sassyColorRed50: '$sassy-color-red-50',
    sassyColorRed200: '$sassy-color-red-200',
    sassyColorRed700: '$sassy-color-red-700',
    sassyColorRed900: '$sassy-color-red-900',
    sassyColorShagreen50: '$sassy-color-shagreen-50',
    sassyColorShagreen300: '$sassy-color-shagreen-300',
    sassyColorShagreen400: '$sassy-color-shagreen-400',
    sassyColorShagreen600: '$sassy-color-shagreen-600',
    sassyColorShagreen700: '$sassy-color-shagreen-700',
    sassyColorShagreen800: '$sassy-color-shagreen-800',
    sassyColorShagreen950: '$sassy-color-shagreen-950',
    sassyColorTangerine500: '$sassy-color-tangerine-500',
    sassyColorYellow50: '$sassy-color-yellow-50',
    sassyColorYellow100: '$sassy-color-yellow-100',
    sassyColorYellow200: '$sassy-color-yellow-200',
    sassyColorYellow300: '$sassy-color-yellow-300',
    sassyColorYellow700: '$sassy-color-yellow-700',
    sassyColorYellow950: '$sassy-color-yellow-950',
    sassyColorGray700: '$sassy-color-gray-700',
    sassyColorCyan50: '$sassy-color-cyan-50',
    sassyColorCyan200: '$sassy-color-cyan-200',
    sassyColorCyan600: '$sassy-color-cyan-600',
    sassyColorWhite: '$sassy-color-white',
    sassyColorAlabaster: '$sassy-color-alabaster',
    sassyColorMoonstone: '$sassy-color-moonstone',
    sassyColorDolphin: '$sassy-color-dolphin',
    sassyColorStorm: '$sassy-color-storm',
    sassyColorIron: '$sassy-color-iron',
    sassyColorSatan: '$sassy-color-satan',
    sassyColorCharcoal: '$sassy-color-charcoal',
    sassyColorNoir: '$sassy-color-noir',
    sassyColorPitchBlack: '$sassy-color-pitch-black',
    sassyColorOverlayBackground: '$sassy-color-overlay-background',
    sassyColorOverlayLight: '$sassy-color-overlay-light',
    sassyColorInternalPrimary: '$sassy-color-internal-primary',
    sassyColorInternalSecondary: '$sassy-color-internal-secondary',
    sassyColorInternalTertiary: '$sassy-color-internal-tertiary',
    sassyColorMarketingSale: '$sassy-color-marketing-sale',
    sassyColorNftWine: '$sassy-color-nft-wine',
    sassyColorMedalBronze: '$sassy-color-medal-bronze',
    sassyColorMedalSilver: '$sassy-color-medal-silver',
    sassyColorMedalGold: '$sassy-color-medal-gold',
    sassyColorMedalPlatinum: '$sassy-color-medal-platinum',
    sassyColorPinterestPrimary: '$sassy-color-pinterest-primary',
    sassyColorFacebookPrimary: '$sassy-color-facebook-primary',
    sassyColorInstagramPrimary: '$sassy-color-instagram-primary',
    sassyColorLinkedinPrimary: '$sassy-color-linkedin-primary',
    sassyColorTradePrimary: '$sassy-color-trade-primary',
    sassyColorTradeSecondary: '$sassy-color-trade-secondary',
    sassyColorTradeTertiary: '$sassy-color-trade-tertiary',
    sassyColorTradeMember: '$sassy-color-trade-member',
} as const;

export type SassyColorNames = keyof typeof colors;
export type SassyColorValues = (typeof colors)[SassyColorNames];
